import { Tag } from 'antd';
import type { TableProps } from 'antd';

interface IDataType {
  key: string;
  name: string;
  progress: string;
  status: string;
}

export const columns: TableProps<IDataType>['columns'] = [
  {
    title: '项目名称',
    dataIndex: 'name',
    key: 'name',
    render: (text) => text,
  },
  {
    title: '项目进展',
    dataIndex: 'progress',
    key: 'progress',
  },
  {
    title: '项目状态',
    dataIndex: 'status',
    key: 'status',
    render: (text) => (
      <Tag
        color={`${text === '进行中' ? 'processing' : text === '已完成' ? 'success' : 'default'}`}>
        {text}
      </Tag>
    ),
  },
];

export const data: IDataType[] = [
  {
    key: '1',
    name: 'sloth-admin 前端项目',
    progress: '10%',
    status: '进行中',
  },
  {
    key: '2',
    name: 'sloth 后端项目',
    progress: '10%',
    status: '进行中',
  },
  {
    key: '3',
    name: 'sloth 帮助文档',
    progress: '0%',
    status: '未开始',
  },
  {
    key: '4',
    name: 'sloth 工作流',
    progress: '0%',
    status: '未开始',
  },
  {
    key: '5',
    name: '导航迁移',
    progress: '0%',
    status: '未开始',
  },
];
