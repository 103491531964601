import { Line } from '@ant-design/plots';
const data = [
  {
    legendName: '男',
    axisName: '论坛发帖',
    value: 15,
  },
  {
    legendName: '男',
    axisName: '网络引擎',
    value: 23,
  },
  {
    legendName: '男',
    axisName: '导航跳转',
    value: 22,
  },
  {
    legendName: '男',
    axisName: '广告宣传',
    value: 18,
  },
  {
    legendName: '男',
    axisName: '熟人推广',
    value: 13,
  },
  {
    legendName: '女',
    axisName: '论坛发帖',
    value: 10,
  },
  {
    legendName: '女',
    axisName: '网络引擎',
    value: 9,
  },
  {
    legendName: '女',
    axisName: '导航跳转',
    value: 20,
  },
  {
    legendName: '女',
    axisName: '广告宣传',
    value: 7,
  },
  {
    legendName: '女',
    axisName: '熟人推广',
    value: 12,
  },
];

const config = {
  data,
  xField: 'axisName',
  yField: 'value',
  seriesField: 'legendName',
  colorField: 'legendName',
};

export default function UserSource() {
  return <Line {...config} height={340} />;
}
